import { CircularProgress, IconButton, styled, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { getLoginURL, getLogoutURL } from '../util/oidc'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { useLocation } from 'react-router-dom'
import { cleanSessionFromLocalStorage, useUserFetch } from '../util/hooks'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))

export type AuthButtonProps = {
  onLogin?: () => void | null
  onLogout?: () => void | null
}

const AuthButton = ({ onLogout, onLogin }: AuthButtonProps) => {
  const [disabled, setDisabled] = useState<boolean>(false)
  const { pathname } = useLocation()

  const { user } = useUserFetch()
  const authenticated = !!user

  // fix for the firefox BFCache
  useEffect(() => {
    const reset = () => setDisabled(false)
    window.addEventListener('pageshow', reset)
    return () => window.removeEventListener('pageshow', reset)
  }, [])

  function login(): void {
    setDisabled(true)
    if (onLogin) onLogin()
  }

  function logout(): void {
    setDisabled(true)
    cleanSessionFromLocalStorage()
    if (onLogout) onLogout()
  }

  if (authenticated) {
    const tooltip = `Mitglied in ${(user.groups || ['keiner Gruppe'])
      .map(g => g.replace('/', ''))
      .join(', ')}`
    return (
      <>
        <LightTooltip title={tooltip}>
          <Typography
            variant="h4"
            style={{ alignSelf: 'center', paddingRight: '6px' }}
          >
            {user.name} {user.surname}
          </Typography>
        </LightTooltip>
        <form key={'logout'} action={getLogoutURL(pathname)} onSubmit={logout}>
          {disabled ? (
            <CircularProgress />
          ) : (
            <Tooltip title="Abmelden">
              <IconButton className="authBtn" type={'submit'}>
                <img src={'/images/exit.svg'} alt={'exit'} />
                <Typography variant="caption">Abmelden</Typography>
              </IconButton>
            </Tooltip>
          )}
        </form>
      </>
    )
  }

  return (
    <form key={'login'} action={getLoginURL(pathname)} onSubmit={login}>
      {disabled ? (
        <CircularProgress />
      ) : (
        <Tooltip title="Login">
          <IconButton className="authBtn" type={'submit'}>
            <img src={'/images/enter.svg'} alt={'enter'} />
            <Typography variant="caption">Login Justiz</Typography>
          </IconButton>
        </Tooltip>
      )}
    </form>
  )
}

export default AuthButton
